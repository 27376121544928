var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require('@/assets/images/logo/logo.png'),
      "alt": "",
      "srcset": ""
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }